* {
  font-family: Manrope;
}
#pageContainer {
  width: 100%;
}
#progressBar {
  height: 80px;
  background-color: #1a1a1a;
  margin-bottom: 30px;
  transition: width 1s;
}
#logo {
  width: 60px;
  // position: absolute;
  // top: 80px;
  // left: 60px;
  z-index: 10;
}

.hide {
  display: none;
  position: absolute;
  // margin-top: 100px;
  top: 24px;
  margin-left: -145px;
  // right: 120px;
  font-size: 14px;
  padding: 20px 20px;
  color: #808080;
  background: #f7f7f7;
  max-width: 400px;
  width: 350px;
  border: 1px solid#D9D9D9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: start;
  z-index: 5;
  animation:fadein .5s;
}

.hide2 {
  display: none;
  position: absolute;
  margin-top: 70px;
  // top: 24px;
  // margin-left: -145px;
  right: 160px;
  font-size: 14px;
  padding: 20px 20px;
  color: #808080;
  background: #f7f7f7;
  // max-width: 400px;
  width: 350px;
  border: 1px solid#D9D9D9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: start;
  z-index: 5;
  animation:fadein .5s;
}

@keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}

// .hide:before {
//   content: "";
//   position: absolute;
//   right: 100%;
//   top: 26px;
//   width: 0;
//   height: 0;
//   border-top: 13px solid transparent;
//   border-right: 26px solid #f7f7f7;
//   border-bottom: 13px solid transparent;
// }

.myDIV:hover + .hide {
  display: block;
  color: #808080;
}


.myDIV2:hover + .hide2 {
  display: block;
  color: #808080;
}

.tooltip{
  display: none;
}

.tooltip-image:hover + .tooltip{
  display: block;
  border: 1px solid rgba(230, 230, 230, 1);
  background: rgba(255, 255, 255, 1);
}

.tooltip-inner {
  font-size: 14px;
  color: #808080;
  background: #f7f7f7;
  max-width: 400px;
  border: 1px solid#D9D9D9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #e6e6e6;
  padding: 0 1.4rem;
}
.sigCanvas {
  border: 2px solid #e6e6e6;
  border-radius: 8px;
  background: #f7f7f7;
}

#exit {
  height: 60px;
  width: 60px;

  border-radius: 50%;
  border: 4px solid #e2abab;
  background: white;
  color: #920e0e;

  font-size: 30px;
  font-weight: 800;
  line-height: 50px;
  text-align: center;

  position: relative;
  display: inline-block;
  // top: 80px;
  // right: 60px;
  z-index: 10;

  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}
#exit:hover {
  text-decoration: none;

  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.371) !important;
}
#whiteContainer {
  min-height: calc(100vh - 45px);
  margin-left: 25px;
  margin-right: 25px;
  background-color: #ffffff;
  border-top-right-radius: 80px 80px;
  border-top-left-radius: 80px 80px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}
#topActions {
  // width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}
#whiteBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
#overlayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-result-extra > *:last-child {
  margin: auto;
}
.ant-select {
  position: relative;
  background-color: #1a1a1a !important;
  color: white !important;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ff660022;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: none !important;
  color: white;
}
.ant-select-arrow {
  color: white;
}
.button {
  text-align: center;
  //   min-width: 250px;
  //   max-width: 550px;
  width: 318px;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px !important;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
  cursor: pointer;
}
.primaryButton,
.primaryButton:hover,
.primaryButton:active {
  // background: #263846 !important;
  border: 0px;
  font-weight: 700 !important;
  fill: #fff !important;
  color: #fff !important;
  background-color: #1a1a1a !important;
  border-radius: 32px;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}
.yougrButton,
.yougrButton:hover,
.yougrButton:active {
  // background: #263846 !important;
  border-radius: 5px;
  border: 0px;
  color: #ffc439 !important;
  font-weight: 700 !important;
  fill: #fff !important;
  color: #fff !important;
  background-color: #ffc439 !important;
  border-radius: 3px 3px 3px 3px !important;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}
.samsungButton,
.samsungButton:hover,
.samsungButton:active {
  // background: #263846 !important;
  border-radius: 5px;
  border: 0px;
  color: black !important;
  font-weight: 700 !important;
  fill: #fff !important;
  color: #fff !important;
  background-color: black !important;
  border-radius: 3px 3px 3px 3px !important;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}

.xiaomiButton,
.xiaomiButton:hover,
.xiaomiButton:active {
  // background: #263846 !important;
  border-radius: 5px;
  border: 0px;
  color: #f26a21 !important;
  font-weight: 700 !important;
  fill: #fff !important;
  color: #fff !important;
  background-color: #f26a21 !important;
  border-radius: 3px 3px 3px 3px !important;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}

.primaryButton:disabled {
  // background: #454d53 !important;
  border: 0px !important;
  // color: #04E502 !important;
  cursor: no-drop;
}

.secondaryButton {
  background: #fdfeff !important;
  color: #016f00 !important;
  // font-weight: 700 !important;
  // fill: #fff !important;
  // color: #fff !important;
  // background-color: #41d900 !important;
  border-radius: 5px !important;
  box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.171) !important;
}
.faulty:hover {
  color: #fc573b !important;
}
.poor:hover {
  color: #ffb229 !important;
}
.acceptable:hover {
  color: #ffb229 !important;
}
.good:hover {
  color: #0985fc !important;
}
.very_good:hover {
  color: #40d6b2 !important;
}
.likeNew:hover {
  color: #40d6b2 !important;
}

.secondaryButton:hover,
.secondaryButton:active {
  background: #fdfeff !important;
  border: 2px solid #263845;
  text-decoration: none !important;
  border: 0px !important;
}
.secondaryButton:disabled {
  background: #fdfeff !important;
  border: 2px solid #263845;
  border-radius: 25px !important;
  border: 0px !important;
  color: #04e502 !important;
}

.couponInput {
  display: inline-block;
  height: 60px;
  width: 100%;
  max-width: 353px;
  font-size: 16px;
  line-height: 30px;
  padding-left: 15px;
  border-radius: 8px;
  background: #f7f7f7;
  border: 2px solid #e6e6e6;
}

.couponInput:focus {
  outline: none;
}

.couponButton {
  background: none !important;
  color: #1a1a1a;
  font-size: 20px !important;
  font-weight: 800;
  border: none;
}

.couponButton:disabled {
  background-color: rgb(188, 188, 188);
}

.invisible {
  visibility: hidden;
}

.fillSpace {
  height: 29px;
}

.successMessage {
  margin-top: 5px;
  margin-bottom: 0;
  color: #04e502;
}

.errorMessage {
  margin-top: 5px;
  margin-bottom: 0;
  color: #fc573b;
}

.myinput {
  /* width: 300px; */
  height: 80px;
  width: 100%;
  background: #eceff2;
  border-radius: 10px;
  color: #04e502;
  border: solid 2px #d6dfe6;
  padding: 0 20px;
  font-size: 30px;
  color: #263846;
  outline: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

#offerBrand {
  line-height: 30px;
  font-size: 30px;
}
#offerModel {
  color: #263846;
  font-size: 35px;
  font-weight: 800;
}
#offerPrice {
  border-radius: 30px;
  border: 10px solid #d6dfe6;

  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #263846;

  padding: 10px 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

#offerPrice2 {
  font-size: 30px;
  font-weight: bold;
  color: #43d900;
}
#offerConditionPill {
  border-radius: 50px;
  width: fit-content;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

#offerConditionPill.likeNew {
  background: #e7faf5 !important;
}

#offerConditionPill.very_good {
  background: #e7faf5 !important;
}

#offerConditionPill.good {
  background: #e4f3ff !important;
}

#offerConditionPill.poor {
  background: #fff5e8 !important;
}

#offerConditionPill.acceptable {
  background: #fff5e8 !important;
}

#offerConditionPill.faulty {
  background: #ffe6e2 !important;
}

#offerConditionRuby {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 10px;
}

#offerConditionRuby.likeNew {
  background: #40d6b2 !important;
}

#offerConditionRuby.very_good {
  background: #40d6b2 !important;
}

#offerConditionRuby.good {
  background: #0985fc !important;
}

#offerConditionRuby.poor {
  background: #ffb229 !important;
}

#offerConditionRuby.acceptable {
  background: #ffb229 !important;
}

#offerConditionRuby.faulty {
  background: #fc573b !important;
}

.method {
  width: 400px;
  height: 400px;
  border: solid 4px #d6dfe6;
  border-radius: 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.title {
  color: #263846;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-dialog {
  width: 90vw !important;
  max-width: 90vw !important;
  padding-right: 20px;
  padding-left: 20px;
}

.voucher {
  padding: 25px;
  background: #eceff2;
  box-shadow: 2px 2px 3px 0px #e6e6e6 !important;
  border-radius: 5px;
}

.terms {
  padding: 0 0 10px 0px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unclickable {
  pointer-events: none;
  cursor: default;
}

.partCondition {
  // border-right: rgba(0, 0, 0, 0.05) 2.5px solid;
  padding: 0 10px;
}

.partCondition:first-child{
  border-right: rgba(0, 0, 0, 0.05) 2.5px solid;
}

.partCondition:last-child {
  border-right: none;
}

.active {
  color: green;
}

.disabled {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  color: #5b6873 !important;
  box-shadow: none !important;
}

.container{
  width: 560px;
}

@media only screen and (max-width: 600px) {
  .container{
    width:  360px;
  }

}
